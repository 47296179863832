import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/feed',
    name: 'Feed',
    component: () => import('../views/Feed.vue')
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import('../views/Events.vue')
  },
  {
    path: '/livestream',
    name: 'Livestream',
    component: () => import('../views/LiveStreams.vue')
  },
  {
    path: '/me',
    name: 'Me',
    component: () => import('../views/MyProfile.vue')
  },
  {
    path: '/invite',
    name: 'Invite',
    component: () => import('../views/Invite.vue')
  },
  {
    path: "/feed/post/:postid",
    name: "post",
    component: () => import('../views/Feed.vue')
  },
  {
    path: "/feed/:id",
    name: "profile",
    component: () => import('../views/Feed.vue')
  },
  {
    path: '/groups',
    name: 'Groups',
    component: () => import('../views/Groups.vue')
  },
  {
    path: '/course',
    name: 'Courses',
    component: () => import('../views/Courses.vue')
  },
  {
    path: '/songs',
    name: 'Songs',
    component: () => import('../views/Songs.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/ProfilesSearch.vue')
  },
  {
    path: '/giving',
    name: 'Giving',
    component: () => import('../views/Gives.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
