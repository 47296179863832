<template lang='pug'>
button.btn(@click="toggleFollowThisProfile" :class="{'btn-outline-danger': following, 'btn-outline-secondary': !following}" :disabled="pending") 
    | {{type === 'User' ? (following ? $t("message.unfollow") : $t("message.follow")) : (following ? $t("message.unsubscribe") : (pending ? $t("message.pending") : $t("message.subscribe")))}}
</template>

<script>
import { UserStore } from "./../../UserStore.js";

export default {
    props:["profile"],
    data(){
        return {
            UserStore
        };
    },
    methods:{
        toggleFollowThisProfile(){
            if(!this.following) return this.UserStore.followProfile(this.profile._id);
            this.UserStore.unfollowProfile(this.profile._id);
        }
    },
    computed:{
        following(){
            return this.UserStore.me.following.includes(this.profile._id);
        },
        type(){
            return this.profile.isGroup ? (this.profile.isCourse ? 'Course' : 'Group') : 'User';
        },
        pending(){
            return this.profile.pending && this.profile.pending[this.UserStore.me._id];
        }
    }
}
</script>

<style>

</style>