const messages = {
    en: {
        message: {
            feed: 'Feed',
            events: "Events",
            livestream: "Livestrem",
            search: 'Search',
            invite: 'Invite',
            groups: 'Groups',
            courses: "Courses",
            songs: "Songs",
            giving: "Giving",
            profile: "Profile",
            settings: 'Settings',
            logout: "Logout",
            statusUpdate: "New Post",
            newsPost: "News Post",
            post: "Post",
            add: 'Add',
            whatIsNew: "What is new?",
            saySomethingTo: "Say something to ",
            follow: "Follow",
            unfollow: "Unfollow",
            subscribe: "Subscribe",
            unsubscribe: 'Unsubscribe',
            pending: "Pending",
            subscribers: "Subscribers",
            loading: "Loading",
            login: 'Login',
            email: "Email",
            password: "Password",
            rpassword: "Repeat Password",
            register: "Register",
            name: "Nombre",
            lastName: "Last Name",
            describeYourself: 'Describe Yourself',
            continueWatching: "Continues Watching",
            createCourse: "Create a your own new course!",
            createGroup: "Create a your own new group!",
            title: "Title",
            subtitle: "Subtitle",
            recentlyAdded: "Recently added",
            popularCourses: "Popular Courses",
            privateGroup: "Private Group",
            tithesAndOfferings: "Tithes and Offerings",
            type: "Type",
            description: "Description",
            amount: "Amount",
            tithes: "Tithes",
            offerings: "Offerings",
            event: "Event",
            proceedToPayment: "Proceed to Payment",
            minimumAmount: "The minimum amount is ",
        },
    },
    es: {
        message: {
            feed: 'Mural',
            events: "Eventos",
            livestream: "En Vivo",
            search: 'Busqueda',
            invite: "Invitaciones",
            groups: 'Grupos',
            courses: 'Cursos',
            songs: "Canciones",
            giving: "Ofrendas",
            profile: "Perfil",
            settings: 'Configuraciones',
            logout: "Cerrar Sesión",
            statusUpdate: "Publicación Nueva",
            newsPost: "Noticias?",
            post: "Publica",
            add: 'Añade',
            whatIsNew: "Que hay de nuevo?",
            saySomethingTo: "Publica algo para ",
            follow: "Seguir",
            unfollow: "No Seguir",
            subscribe: "Afiliar",
            unsubscribe: 'Abandonar',
            pending: "Pendiente",
            subscribers: "Subscriptores",
            loading: "Cargando",
            login: 'Inicia Sesión',
            email: "Correo Electronico",
            password: "Contraseña",
            rpassword: "Confirma tu Contraseña",
            register: "Registrate",
            name: 'Nombre',
            lastName: "Apeido",
            describeYourself: 'Describete en unas cuantas palabras',
            continueWatching: "Continua viendo",
            createCourse: "Crea tu propio curso!",
            createGroup: "Crea un grupo nuevo!",
            title: "Titulo",
            subtitle: "Subtitulo",
            recentlyAdded: "Recientemente añadido",
            popularCourses: "Cursos populares",
            privateGroup: "Grupo privado",
            tithesAndOfferings: "Diezmos y Ofrendas",
            type: "Tipo",
            description: "Descripción",
            amount: "Cantidad",
            tithes: "Diezmos",
            offerings: "Ofrendas",
            event: "Evento",
            proceedToPayment: "Continuar con el pago",
            minimumAmount: "La cantidad mínima es de ",
        },
    }
}


export default messages;