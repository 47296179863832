<template lang="pug">
.Register.container.text-start
	h1 {{ $t("message.register") }}
	form#register-form(@submit.prevent)
		.row
			.col-6.form-floating           
				input( placeholder='Name' v-model="firstName").form-control#name
				label {{ $t("message.name") }}
			.col-6.form-floating   
				input(placeholder='Last Name' v-model="lastName").form-control#lastName
				label {{ $t("message.lastName") }}
		br
		.row
			.col-12.form-floating
				input(placeholder='Email' v-model="email" type="email").form-control#EmailInput
				label(for="EmailInput") {{ $t("message.email") }}
		br
		.row
			.col-6.form-floating
				input(placeholder='Password' v-model="password" type="password").form-control#PasswordInput
				label(for="PasswordInput") {{ $t("message.password") }}
			.col-6.form-floating
				input(placeholder='Password2' v-model="password2" type="password").form-control#Password2Input
				label(for="Password2Input") {{ $t("message.rpassword") }}
		br
		.row.mb-3
			.col-12.form-floating
				textarea.form-control#DescriptionInput(placeholder="Describe yourself" v-model="description")
				label(for="DescriptionInput") {{ $t("message.describeYourself") }}
		.text-center
			button.btn.btn-primary(@click="register") {{ $t("message.register") }}
</template>

<script>
import API from "./../API.js";

export default {
	data(){
		return {
			email: "",
			password: '',
			password2: '',
			firstName: "",
			lastName: "",
			description: ""
		}
	},
	methods:{
		register(){
			if(!this.password || this.password != this.password2)
				alert("Passwords do not match!")
			API.signup(this.email, this.password, this.email, {
						firstName: this.firstName,
						lastName: this.lastName,
						description: this.description
					})
			.then(data => {
				if(data && data.status && data.status === 'ok') return this.$router.push('Feed');
				alert(data.status)
			}).catch(console.log);
		}
	}
}
</script>

<style lang="sass">

</style>