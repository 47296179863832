<template lang='pug'>
img.rounded-circle(:src="computedSrc" onerror="this.src='https://social.emmint.com/uploads/e6f9be6d665dc43417701bf16a90122c.pn?width=1000&height=1000g';this.onerror='';")
</template>

<script>
export default {
    props: ["src", "type"],
    computed:{
        computedSrc(){
            // Default Image
            if(!this.src) return "https://social.emmint.com/uploads/e6f9be6d665dc43417701bf16a90122c.png?width=1000&height=1000";
            // Complete Url
            if(this.src.substring(0,5).includes("http")) return this.src;
            // Relative path
            if(this.src.includes("?width=")){
                return "https://social.emmint.com/" + this.src;
            }
            return "https://social.emmint.com/" + this.src + "?width=1000&height=1000";
        }
    }
}
</script>

<style lang='sass' scoped>
img
    width: 100%
    aspect-ratio: 1
    object-fit: cover
</style>