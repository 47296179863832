<template lang="pug">
.home.container.text-start
  h1 {{ $t("message.login") }}
  form#login(@submit.prevent)
    .form-floating
      input#EmailInput.form-control(type='email' v-model="username" placeholder="Email" @click="error = ''")
      label(for="EmailInput") {{ $t("message.email") }}
    br
    .form-floating
      input#PasswordInputLogin.form-control(type='password' v-model="password" placeholder="password" @click="error = ''")
      label(for="PasswordInputLogin") {{ $t("message.password") }}
    br
    p.error.text-danger.text-capitalize.text-center(v-if="error") {{error}}
    .text-center
      button.btn.btn-primary(@click='login') {{ $t("message.login") }}
      button.btn.btn-danger.m-1(v-if="error == 'incorrect password'" @click='resetPassword') Reset Password
  br
  br
  Register
</template>

<script>
import Register from "./../components/Register.vue";
import API from "./../API.js";
import { UserStore } from "../UserStore";

export default {
  name: 'Home',
  components: {
    Register
  },
  data(){
    return {
      username: "",
      password: "",
      error: "",
      UserStore
    }
  },
  methods:{
    login(){
      API.logIn(this.username.toLowerCase(), this.password)
        .then((data) => {
          if(data && data.status && data.status === 'ok'){
            this.$router.push('Feed')
            this.UserStore.getMe();
          }else{
            this.error = data.status
          }
            
        });
    },
    resetPassword(){
      API.resetPassword(this.username.toLowerCase()).then((data) => {
          if(data && data.status && data.status === 'ok'){
            this.error = data.details
          }else{
            this.error = data.status
          }
        });
    }
  },
  mounted(){
    API.isLoggedIn().then((r)=>{
      if(r) this.$router.push('Feed')
    });
  }
}
</script>

<style lang="sass">

</style>