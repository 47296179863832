import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap/dist/js/bootstrap'
import { createI18n } from 'vue-i18n'
import messages from "./i18nMessages.js"
import moment from 'moment'
window.moment = moment;
import store from "store"
window.store = store;

const browserLang = navigator.language.substring(0, 2) || "en";

const i18n = createI18n({
    locale: browserLang, // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
    // If you need to specify other options, you can set other options
    // ...
})

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'



createApp(App).use(i18n).use(router).mount('#app')
