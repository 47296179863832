// store.js
import { reactive } from 'vue';
import API from './API.js';

export const UserStore = reactive({
  me: {},
  myProfiles: [],
  myProfilesMap: {},
  async getMe() {
    this.me = await API.getMe();
    API.getMyProfiles().then((data) => {
      this.myProfiles = data.profiles;
      data.profiles.forEach(p => {
        this.myProfilesMap[p._id] = p;
      });
    });
  },
  async followProfile(profileid) {
    // Simulates the changes on the DB to speed up the UI
    if (!profileid || profileid == this.me._id) return false;
    const profile = await API.getUserProfile(profileid);
    if (!this.me.following) this.me.following = [];
    this.me.following.push(profileid);
    if (!profile.isGroup) { // Follow user profile
      return await API.followProfile(profileid);
    }
    let reload = false;
    if (!profile.isPrivate) {
      if (!profile.subscribed) profile.subscribed = {};
      profile.subscribed[this.me._id] = new Date();
    } else {
      if (!profile.pending) profile.pending = {};
      profile.pending[this.me._id] = new Date();
      reload = true;
    }
    let r = await API.subscribeToGroup(profileid)
    if(reload) location.reload(); //TODO: Please change this to proper reactivity
    return r
  },
  async unfollowProfile(profileid) {
    // Simulates the changes on the DB to speed up the UI
    if (!profileid) return false;
    const profile = await API.getUserProfile(profileid);
    this.me.following = this.me.following.filter((pId) => {
      return pId != profileid;
    });
    if (!profile.isGroup) { // For user profiles
      return await API.unfollowProfile(profileid);
    }
    // For groups or courses
    profile.subscribed[profileid] = undefined;
    return await API.unsubscribeToGroup(profileid)
  },
  async changeActiveProfile(profileid) {
    await API.changeProfile(profileid);
    await this.getMe();
  }
})