<template lang="pug">
.User-And-Messages.text-start(v-if="me.profile")
    //.change-active-profile
        ProfileCard(:id='UserStore.me._id' style="width: 200px;")
        p.current-profile(@click="goToProfile")
            //span.material-icons(style="width: 27px;") account_circle_outlined
            //span {{UserStore.me.profile.firstName + " " + UserStore.me.profile.lastName}}
        p.other-profile(v-for="p in profiles" v-if="me.superuser || me.isGroup").text-secondary
            ProfileCard(:id='p._id' v-if="p._id !== UserStore.me._id" @click="toProfile(p._id)" style="width: 200px;")
            //button.btn.wrap-profile(v-if="p._id !== UserStore.me._id" @click="toProfile(p._id)")
                span.material-icons(style="width: 27px;") account_circle_outlined
                span {{p.profile.firstName + " " + p.profile.lastName}}
</template>

<script>
import API from "./../API.js";
import { UserStore } from './../UserStore.js';
import ProfileCard from './../components/ProfileCard.vue'

export default {
    name: 'UserAndMessages',
    components:{ProfileCard},
    data(){
        return {
            me: {},
            profiles: [],
            UserStore,
        }
    },
    mounted(){
        this.me = this.$attrs.user;
        API.getMyProfiles().then((data) =>{
            this.profiles = data.profiles;
        });
    },
    methods:{
        toProfile(profileid){
            //API.changeProfile(profileid).then(()=>{
            //    location.reload();
            //});
            this.UserStore.changeActiveProfile(profileid);
        },
        goToProfile(){
            console.log("Going to profile")
            this.$router.push('/Me')
        }
    }
}
</script>

<style>

</style>