<template lang='pug'>
.mobile-menu.ul.nav.justify-content-center(v-if="UserStore.me._id")
    //li.nav-item
        router-link(to="/feed")
            span.material-icons.m-1 stream
            br
            | Feed
    li.nav-item(@click="$router.push('/feed')")
        span.material-icons.big stream
        br
        | {{ $t("message.feed") }}
    li.nav-item(@click="$router.push('/feed/' + UserStore.me._id)")
        span.material-icons.big person_outline
        br
        | {{ $t("message.profile") }}
    li.nav-item(@click="$router.push('/search')")
        span.material-icons.big  search
        br
        | {{ $t("message.search") }}
    li.nav-item(@click="$router.push('/groups')")
        span.material-icons.big  groups
        br
        | {{ $t("message.groups") }}
    li.nav-item(@click="$router.push('/course')")
        span.material-icons.big  subscriptions
        br
        | {{ $t("message.courses") }}

</template>

<script>
import { UserStore } from './../UserStore.js'

export default {
data(){
    return {
        UserStore
    }
}
}
</script>

<style scoped>
.mobile-menu{
    display: none;
}
@media (max-width: 900px){
    .mobile-menu{
        display: flex;
    }
}
.nav{
    background: white;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    box-shadow: 1px 0px 13px 2px rgb(0 0 0 / 10%);
    overflow-x: auto;
    flex-wrap: nowrap !important;
}
.big{
    font-size: 2em;
}
.nav-item{
    padding: 5px 15px
}
</style>