<template lang="pug">
span.user-name.pointer
  .img-container(v-if="showImgCircle")
    ImageCircle(:src="profile && profile.profile ? profile.profile.photo + '?width=100&height=100' : null" :type="type" )
  span.material-icons(v-if="!profile.isGroup && !showImgCircle") {{ profile.subscription && profile.subscription > (new Date() - 0) ? "assignment_ind" : "person_outline" }}
  span.material-icons(v-if="profile.isGroup && !profile.isCourse") {{profile.isPrivate ? "admin_panel_settings" : "group"}}
  span.material-icons(v-if="profile.isGroup && profile.isCourse") subscriptions
  span.name(v-if="profile && profile.profile && profile.profile.firstName", @click="goToProfile") {{ profile.profile.firstName }} {{ profile.profile.lastName }}
</template>

<script>
import API from "./../API.js";
import ImageCircle from "./fundamentals/ImageCircle.vue";

export default {
  name: "UserName",
  props: ["id", "showImgCircle"],
  components: {ImageCircle},
  data() {
    return {
      profile: {},
    };
  },
  mounted() {
    this.refresh(this.id);
  },
  methods: {
    goToProfile() {
      this.$router.push("/feed/" + this.id);
    },
    refresh(id) {
      API.getUserProfile(id).then((data) => {
          this.profile = data;
      });
    },
  },
  watch: {
    $route() {
      //Names acts werid goig back to feed
      let userid = this.id;
      this.refresh(userid);
    },
  },
};
</script>

<style lang="sass" scoped>
.img-container
  width: 50px
  display: inline-block
  margin-right: 6px
.name
  

.user-name
  display: flex
</style>